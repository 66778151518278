import { AgChartsReact } from "ag-charts-react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import styled from "styled-components";
import { HumidityCircle } from "./components/HumidityCircle";
import { TemperatureCircle } from "./components/TemperatureCircle";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Graphs />
        </QueryClientProvider>
    );
};

const Graphs = () => {
    const { data } = useQuery("values", async () => {
        const start = new Date();
        start.setHours(start.getHours() - 1);
        const end = new Date();

        const url = new URL(
            "https://md-humidity-temp-fn.azurewebsites.net/api/GetValues"
        );
        url.searchParams.append("start", start.toISOString());
        url.searchParams.append("end", end.toISOString());

        const response = await fetch(url);
        return response.json();
    });

    const humidity = data
        ? data.map((v: any) => {
              const date = new Date(v.time);
              return {
                  minute: `${date.getHours()}:${date.getMinutes()}`,
                  humidity: v.humidity,
              };
          })
        : [];

    const temp = data
        ? data.map((v: any) => {
              const date = new Date(v.time);
              return {
                  minute: `${date.getHours()}:${date.getMinutes()}`,
                  temperature: v.temperature,
              };
          })
        : [];

    const latestTemp = temp.length ? temp[temp.length - 1].temperature : 5;
    const latestHumidity = humidity.length
        ? humidity[humidity.length - 1].humidity
        : 0;

    const options = {
        autoSize: true,
        title: {
            text: "Humidity (%)",
        },
        subtitle: {
            text: "Per minute (1h)",
        },
        data: humidity,
        series: [
            {
                xKey: "minute",
                yKey: "humidity",
            },
        ],
        legend: {
            enabled: false,
        },
    };

    const tempOptions = {
        autoSize: true,
        title: {
            text: "Temperature (C)",
        },
        subtitle: {
            text: "Per minute (1h)",
        },
        data: temp,
        series: [
            {
                xKey: "minute",
                yKey: "temperature",
            },
        ],
        legend: {
            enabled: false,
        },
    };

    return (
        <div>
            <SummaryContainer>
                <TemperatureCircle temperature={latestTemp} />
                <HumidityCircle humidity={latestHumidity} />
            </SummaryContainer>
            <AgChartsReact options={options} />
            <AgChartsReact options={tempOptions} />
        </div>
    );
};

const SummaryContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    margin-top: 24px;

    div:not(:last-of-type) {
        margin-right: 24px;
    }
`;

export default App;
