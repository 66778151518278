import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";

export const HumidityCircle = ({ humidity }: HumidityCircleProps) => {
    const formattedHumidity = `${humidity.toFixed(1)}%`;

    return (
        <Container>
            <CircularProgressbar
                value={humidity}
                text={formattedHumidity}
                minValue={0}
                maxValue={100}
                strokeWidth={12}
                styles={buildStyles({
                    pathColor: getPathColor(humidity),
                    textColor: "black",
                })}
            />
        </Container>
    );
};

const getPathColor = (humidity: number) => {
    if (humidity <= 60) {
        return "green";
    } else if (humidity <= 65) {
        return "orange";
    } else {
        return "red";
    }
};

const Container = styled.div`
    width: 160px;
    height: 160px;
`;

interface HumidityCircleProps {
    humidity: number;
}
