import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";

export const TemperatureCircle = ({ temperature }: TemperatureCircleProps) => {
    const formattedTemp = `${temperature.toFixed(1)}C`;

    return (
        <Container>
            <CircularProgressbar
                value={temperature}
                text={formattedTemp}
                minValue={0}
                maxValue={30}
                strokeWidth={12}
                styles={buildStyles({
                    pathColor: getPathColor(temperature),
                    textColor: "black",
                })}
            />
        </Container>
    );
};

const getPathColor = (temperature: number) => {
    if (temperature <= 15) {
        return "blue";
    } else if (temperature <= 23) {
        return "green";
    } else {
        return "orange";
    }
};

const Container = styled.div`
    width: 160px;
    height: 160px;
`;

interface TemperatureCircleProps {
    temperature: number;
}
